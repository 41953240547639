.experience__style{
    background-color:#fff;
    padding: 10px;
    padding-top: 50px;
    }
    
    .app__about {
        flex: 1;
        width: 100%;
        flex-direction: column;
       
      }
      
      .app__profiles {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 2rem;
      }
      
      .app__profile-item {
        width: 270px;
        height: 290px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        margin: 2rem;
        border: 1px solid darkblue;
        border-radius: 10px;
        padding: 10px;
    
        img {
          width: 100%;
          height: 170px;
          border-radius: 5px;
          object-fit: cover;
        }
      
        @media screen and (min-width: 2000px) {
          width: 400px;
          margin: 2rem 4rem;
      
          img {
            height: 320px;
          }
        }
      }
      
      