.about__style {
  background-color: #fff;
  padding: 10px;
  padding-top: 50px;
}

.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__about-profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.app__about-profile-item {
  width: 220px;
  height: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;
  border: 1px solid darkblue;
  border-radius: 30px;
  padding: 10px;

  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }
}

.sticky__about-back {
  background-image: url(../../assets/sticky-back.jpg);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  width: 100%;
  color: #fff;
  font-size: 60px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  padding: 20px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 25px;
}

.sticky__about-back p {
  text-align: center;
  vertical-align: middle;
}
