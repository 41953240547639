#home {
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: repeat;
    padding-top: 20x;
    overflow-x: hidden;
    overflow-y: hidden;
    // disable the default padding styles
    .app__wrapper {
      padding: 0;
  
      .copyright {
        display: none;
      }
    }
  }

  .hero{
    display: flex;
    height: 96vh;
  }
  
  /* left side styling */
.i-left {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;
  margin-top:3rem;
  margin-left:3rem;
}
.i-name {
  display: flex;
  flex-direction: column;
  gap: 0.15rem;
}
.i-name > :nth-child(1) {
  color: darkblue;
  font-size: 1rem;
  font-weight: bold;
}
.i-name > :nth-child(2) {
  color:rgb(9, 58, 255);
  font-weight: bold;
  font-size: 7rem;
  text-shadow: #ff5e00 1.5px 1.5px 1px;
}
.i-name > :nth-child(3) {
  font-weight: 100;
  font-size: 15px;
  color: var(--gray);
  margin-top: 10px;
}
.i-name > :nth-child(4) {
  display: none;
}

/* right side styling */
.i-right {
  flex: 1;
  position: relative;
}
.i-right > * {
  position: absolute;
  z-index: 1;
}
.i-right > :nth-child(1) {
  transform: scale(1);
  top: -9%;
}
.i-right > :nth-child(2) {
  transform: scale(1.3);
  left: 10rem;
}
.i-right > :nth-child(3) {
  transform: scale(0.96);
}
.i-right > :nth-child(4) {
  transform: scale(0.62);
  // top: -19%;
  left: -24%;
  border-radius: 50%;
  padding: 0;
}

#intro{
  line-height: 1.5;
}
@media screen and (max-width: 480px) {
  .hero {
    flex-direction: column;
  }
  .i-left{
    flex:none;
  }

  .i-right {
    transform: scale(0.5);
    left: -120px;
    top:-30px;
  }
  .i-right .blur {
    display: none;
  }
  // .floating-div:nth-of-type(1){
  //   /* display: none; */
  //   top: -7rem !important;;
  // }
  .i-name > :nth-child(2) {
    font-size: 5rem;
    line-height: 5rem;
  }
  .i-name > :nth-child(3) {
    display: none;
  }
  .i-name > :nth-child(4) {
    display: block;
  }
}