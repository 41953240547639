.app__works {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__work-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  margin: 4rem 0 2rem;

  .app__work-filter-item {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;
    font-weight: 800;

    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;

    &:hover {
      background-color: var(--secondary-color);
      color: #fff;
    }

    @media screen and (min-width: 2000px) {
      padding: 1rem 2rem;
      border-radius: 0.85rem;
    }
  }

  .item-active {
    background-color: var(--secondary-color);
    color: #fff;
  }
}

.app__work-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Change from center to space-between */
  align-items: flex-start; /* Align items at the start */

  .app__work-item {
    width: calc(33.333% - 4rem); /* Ensure 3 cards per row */
    margin: 1rem; /* Adjust margin to fit */
    height: 35rem;
    padding: 3rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;
    flex-direction: column;

    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    }

    @media screen and (max-width: 1200px) {
      width: calc(50% - 2rem); /* Adjust for smaller screens */
    }

    @media screen and (max-width: 768px) {
      width: calc(100% - 2rem); /* Full width on small screens */
    }
  }
}

.app__work-img {
  width: 100%;
  height: 230px;
  border: 1px solid darkblue;
  border-radius: 0.5rem;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    height: 350px;
  }
}

.app__work-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  border-radius: 0.5rem;
  opacity: 0;
  transition: all 0.3s ease;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;

    margin: 1rem;
    font-family: var(--font-base);
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;

    svg {
      width: 50%;
      height: 50%;
      color: var(--white-color);
    }
  }
}

.app__work-content {
  padding: 0.5rem;
  width: 100%;
  position: relative;
  flex-direction: column;

  h4 {
    margin-top: 1rem;
    line-height: 1.5;

    @media screen and (min-width: 2000px) {
      margin-top: 3rem;
    }
  }

  .app__work-tag {
    position: absolute;

    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: #fff;
    top: -25px;
  }

  .app__work-readmore {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .read-more-button {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background: transparent;
    color: #48a0ff;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s ease;
    border: 2px solid #48a0ff;

    &:hover {
      color: #fff;
      background:#48a0ff;
    }
  }
  
.show-more-button{
  padding: 0.5rem 1rem;
  // border-radius: 0.5rem;
  background: transparent;
  color: #48a0ff;
  text-align: center;
  transition: all 0.3s ease;
  text-decoration: underline;
  // border: 2px solid #48a0ff;

  &:hover {
    color: #fff;
    background:#48a0ff;
  }
}

}




.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.popup-inner {
  position: relative;
  padding: 2rem;
  border-radius: 0.5rem;
  background-color: #fff;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);

  img {
    width: 25%;
    height: auto;
    border-radius: 0.5rem;
    margin: 0 auto;
    display: block;
  }

  h2 {
    margin-bottom: 1rem;
    text-align: center;
  }

  p {
    margin-top: 2rem;
    font-size: 1.2rem;
  }

  .app__work-tag {
    margin-top: 1rem;
    display: flex;
    justify-content: center;

    .p-text {
      background-color: #f0f0f0;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      margin-right: 0.5rem;
    }
  }

  .publication-details {
    margin-top: 1rem;
    text-align: center;


    p {
      background-color: #f0f0f0;
      padding: 1rem 2rem;
      border-radius: 0.5rem;
      margin-right: 0.5rem;
    }
  }

  .download-button {
    display: block;
    width: 200px;
    padding: 0.5rem 1rem;
    margin: 2rem auto 0;
    background: transparent;
    color: #48a0ff;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s ease;
    border: 2px solid #48a0ff;
    border-radius: 0.5rem;
    cursor: pointer;
    font-size: 1rem;
    &:hover {
      color: #fff;
      background:#48a0ff;
    }
  }

  .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
}
