.section__padding {
  padding: 2rem 2rem;
}

.footer {
  background-color: #424ed1;
}

.foot__logo {
  height: 100px;
  width: 100px;
}

.foot__logo__name {
  height: 15px;
  width: 150px;
  margin-bottom: 40px;
}

.sb__footer {
  display: flex;
  flex-direction: column;
}

.sb__footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  text-align: left;
  margin-bottom: 0.8rem;
}

#map{
  border-radius: 20px;
}
.sb__footer-links-div {
  width: 150px;
  margin: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  color: #fff;

  &:nth-child(1) {
    flex-basis: 25%;
  }

  &:nth-child(2) {
    flex-basis: 15%;
  }

  &:nth-child(3) {
    flex-basis: 12%;
  }

  &:nth-child(4) {
    flex-basis: 22%;
  }

  a {
    color: rgb(175, 175, 179);
    text-decoration: none;

    &:hover {
      color: var(--color-orange);
    }
  }

  h4 {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0.9rem;
  }

  p {
    font-size: 12px;
    line-height: 15px;
    margin: 0.5rem 0;
    cursor: pointer;
  }
}

.socialmedia {
  display: flex;
  flex-direction: row;
  font-size: 30px;

  a {
    color: rgb(255, 255, 255);
    margin: 5px;

    &:hover {
      color: var(--color-orange);
      transition: ease 0.5s;
    }
  }
}

.sb__footer-below {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.2rem;
}

.sb__footer-below-links {
  display: flex;
  flex-direction: row;

  p {
    font-size: 13px;
    line-height: 15px;
    margin-left: 2rem;
    color: white;
    font-weight: 600;
  }
}

hr {
  color: #fff;
  width: 100%;
  margin-bottom: 40px;
}

.sb__footer-copyright {
  font-size: 13px;
  line-height: 15px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  margin-top: 0.2rem;
}

.flag {
  display: none;
}

@media screen and (max-width: 850px) {
  .sb__footer-heading {
    font-size: 44px;
    line-height: 50px;
  }

  .sb__footer-below {
    flex-direction: column;
    justify-content: left;
  }

  .sb__footer-below-links {
    flex-direction: column;
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .foot__logo__name {
    height: 10px;
    width: 100px;
  }
}

@media screen and (max-width: 550px) {
  .sb__footer-heading h1 {
    font-size: 34px;
    line-height: 42px;
  }

  .sb__footer-links div {
    margin: 1rem 0;
  }

  .sb__footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }

  .sb__footer-below {
    flex-direction: column;
    justify-content: left;
  }

  .sb__footer-below-links {
    flex-direction: column;
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .foot__logo__name {
    height: 10px;
    width: 100px;
  }
}

@media screen and (max-width: 400px) {
  .sb__footer-heading h1 {
    font-size: 27px;
    line-height: 38px;
  }

  .sb__footer-links div {
    margin: 1rem 0;
  }

  .sb__footer-btn p {
    font-size: 14px;
    line-height: 20px;
  }

  .sb__footer-below {
    flex-direction: column;
    justify-content: left;
  }

  .sb__footer-below-links {
    flex-direction: column;
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .foot__logo__name {
    height: 10px;
    width: 100px;
  }
}
